<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="导出模块：">
                <el-select v-model="form.type" size="small" clearable>
                    <el-option label="全部" value=""></el-option>
                    <el-option :label="item.name" :value="item.val" v-for="item in type" :key="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="日期选择：">
                <el-date-picker size="small" class="search_input" v-model="Registerdate" type="daterange"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"
                    value-format="timestamp"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <div class="indexbox">
            <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <!-- <template slot="empty">
        <No />
      </template> -->
        <el-table-column prop="mobile" label="导出模块" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.type == '1' ? '运营商收益统计' : scope.row.type == '3' ? '运营商商家统计' : '提现审核' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="导出日期" min-width="120" align="center">
          <template slot-scope="scope">
            <span>{{ getDateformat(scope.row.create_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="筛选时间" min-width="120" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.screen_time">全部</span>
            <div v-else>
              <span>{{ getDateformat(scope.row.screen_time[0]) }}</span>
              <br />
              <span>{{ getDateformat(scope.row.screen_time[1]) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template v-slot="{ row }">
            <el-button @click="down(row.file_url)" type="text">下载</el-button>
            <el-button @click="del(row.id)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging class="paging" :total="totalNum" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </div>
  </el-main>
</template>
   
<script>
import _ from 'lodash';
import Paging from '@/components/paging.vue';
import config from '@/util/config';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            templatePath: config.baseurl + '/' + 'Upload/import/运营商收益导入模板.xlsx',
            realData: [],
            totalNum: {},
            form: {
                page: 1,
                rows: 10,
                type: ''
            },
            userList: [],
            type: [
                {
                    val: 1,
                    name: '运营商收益统计'
                },
                {
                    val: 2,
                    name: '运营商商家统计'
                },
                {
                    val: 3,
                    name: '商家列表'
                },
                {
                    val: 4,
                    name: '商家审核列表'
                }
            ],
            Registerdate: ''
        };
    },
    created () {
        this.getIndexData();
    },
    methods: {
        getDateformat,
        getIndexData () {
            if (this.Registerdate) {
                this.form.start_time = this.Registerdate[0] / 1000;
                this.form.end_time = this.Registerdate[1] / 1000;
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            this.$axios.post(this.$api.set.ExportFile, this.form).then(res => {
                if (res.code == 0) {
                    this.userList = res.result.list;
                    this.userList.forEach(item => {
                        if (item.screen_time) {
                            item.screen_time = JSON.parse(item.screen_time);
                        }
                    });
                    this.totalNum = res.result.total_number;
                }
            });
        },
        search () {
            this.getIndexData();
        },
        down (url) {
            this.templatePath = config.baseurl + '/' + url;
            let path = this.templatePath;
            let a = document.createElement('a');
            a.href = path;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
        },
        del (id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios
                        .post(this.$api.set.ExportFileDel, {
                            id: id,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                this.getIndexData();
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!',
                                });
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 下一页
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getIndexData();
            } else {
                this.form.page = val;
                this.getIndexData();
            }
        },

        // 排序
        getMidLeft (order) {
            if (order == 'asc') {
                this.form.order = 'desc';
            } else {
                this.form.order = 'asc';
            }
            this.getIndexData();
        },

        // 清空搜索
        clearSearch () {
            this.form = {
                page: 1,
                rows: 10,
                order: 'asc',
            };
            this.getIndexData();
        },

        // 导出
        exportEx () {
            this.$axios
                .post(this.$api.set.earningsStatisticsExport, {
                    name: this.form.name,
                    order: this.form.order,
                    start_time: this.form.start_time,
                    end_time: this.form.end_time,
                })
                .then(res => {
                    if (res.code == 0) {
                        // this.$axios
                        //   .post(this.$api.set.ExportFile, {
                        //     page: this.form.page,
                        //     rows: this.form.rows,
                        //     start_time: this.form.start_time,
                        //     end_time: this.form.end_time
                        //   })
                        //   .then(res => {
                        //     if (res.code == 0) {
                        //
                        //   });
                    }
                })
                .catch(err => {
                    console.log(err);
                });

            //   if (this.form.name) obj.name = this.form.name;
            //   if (this.form.order) obj.order = this.form.order;
        },
    },
};
</script>
  
<style lang="less" scoped>
.indexbox {
    font-size: 14px;

    .indexBoxLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 8px;
        background-color: #fff;

        .realtime {
            .realtimebot {
                margin-top: 20px;
                padding: 0 30px 23px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .listItem {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;

                    .computed {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                    }

                    &+.listItem {
                        margin-top: 20px;
                    }

                    .topItem {
                        flex-shrink: 0;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        // padding: 12px 20px 0;
                        align-items: center;

                        .today {
                            text-align: left;

                            p {
                                font-size: 28px;
                                font-weight: bold;
                                line-height: 30px;
                            }

                            p:first-child {
                                white-space: nowrap;
                                font-weight: 400;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .indexBoxRight {
        flex: 10;
        min-width: 478px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .righttop {
            height: 388px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 8px;
            background-color: #fff;

            &>div {
                width: 100%;
                // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
            }

            .righttopT {
                height: 388px;
                padding: 16px 24px 20px 24px;
                margin-bottom: 8px;
                display: flex;
                flex-direction: column;

                .titleBox {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #17171a;

                        &:before {
                            content: '';
                            height: 14px;
                            border-right: 3px solid #1467ff;
                            margin-right: 8px;
                        }
                    }

                    .ignoreall {
                        color: #979899;
                        cursor: pointer;
                    }
                }

                .listBox {
                    flex: 1;
                    padding-right: 10px;
                    overflow: hidden;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                        height: 5px !important;
                    }

                    .itemBox {
                        display: flex;
                        margin-bottom: 12px;
                        justify-content: space-between;
                        align-items: center;

                        .content {
                            width: 192px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #656566;

                            span {
                                display: inline-block;
                                width: 4px;
                                height: 4px;
                                margin-bottom: 2.5px;
                                background: #1467ff;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
                        }

                        .time {
                            color: #cacbcc;
                            font-size: 12px;
                        }

                        .todeal {
                            color: #1467ff;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.charts {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
}

.realtimetop {
    display: flex;
    align-items: center;

    .title {
        font-weight: bold;
        font-size: 14px;
        padding: 20px 0;

        &:before {
            content: '';
            height: 14px;
            margin-right: 8px;
        }

        span,
        i {
            color: #409eff;
        }

        span {
            margin: 0 10px;
            font-size: 15px;
        }

        i {
            transform: rotate(90deg);
            font-size: 18px;
            cursor: pointer;
        }
    }

    .timeTxt {
        margin: 0 8px 0 16px;
        color: #969699;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;

    .px {
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
            font-size: 12px;
        }
    }
}

.el-main {
    background: #fff;

    .total-num {
        display: flex;
        font-weight: bold;
        margin: 20px 0;

        div {
            margin-right: 40px;
        }
    }

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}
</style>
  